import { useEffect, lazy } from "react";
import { HelmetProvider } from '../custom_modules/react-helmet-async';
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import messages from "../assets/locals/messages";
import { generateRoutes } from "../routes/guardedRoutes/AllowedRoutes";
import History from "../routes/History";
import Routes from "../routes/Routes";
import Loader from "../components/Loader";
import { setCurrentLang } from "../store/Lang/actions";
import { GenerateNewRoutes } from "../store/Auth/actions";
import { roles } from "../utils/Constants";
/////////
import dayjs from 'dayjs';  
import customParseFormat from 'dayjs/plugin/customParseFormat';  
import localizedFormat from 'dayjs/plugin/localizedFormat';  
import 'dayjs/locale/ar';  
//////////////////////////////
import "./App.scss";
//-----------live-config-----------
import "../utils/logrocket";
//-----------------------------------

const MaterialSnackbar = lazy(() => import("../components/Snackbar/Snackbar"));
const SessionExpiration = lazy(() => import("../components/sessionExpiration"));

const App = () => {
  dayjs.extend(customParseFormat);  
dayjs.extend(localizedFormat); 


  const dispatch = useDispatch();
const injectBootstrap = async() =>{
  if(window.location.pathname !== '/'){
    await import("bootstrap/dist/css/bootstrap.rtl.min.css");
  }
}
useEffect(() => {
  injectBootstrap()
}, [window.location.pathname ])
  const {
    auth: { user, routes },
    socket: { isSocketConnected },
    firmSettings: { generalFirmSettings },
    lang,
    loader,
  } = useSelector((state) => state);

  const userRoles =
    user?.role || JSON.parse(localStorage.getItem("userInfo"))?.role;

  const handleFirmSetting = async () => {
      if (!Object.keys(generalFirmSettings).length &&
        user &&
        user.token &&
        userRoles?.find((role) => role !== roles.SUPER_ADMIN)) {
        const { getFirmSettings } = await import("../store/FirmSettings/actions");
        dispatch(getFirmSettings({ option: "general" }));
        dispatch(getFirmSettings({ option: "app" }));
      }
  }
  useEffect(() => {
    handleFirmSetting()
  }, [generalFirmSettings]);

  // const handleMomentLocal = async (currentLang) => {
  //     if (currentLang === "ar") {
  //       const moment = await import("moment");
  //       const { arabicDays, arabicMonths } = await import("../utils/Constants");
  //       moment.locale(currentLang, {
  //         months: arabicMonths,
  //         monthsShort: arabicMonths,
  //         weekdays: arabicDays,
  //         weekdaysShort: arabicDays,
  //         meridiem: function (hours) {
  //           return hours < 12
  //             ? messages[currentLang].shared.morning
  //             : messages[currentLang].shared.evening;
  //         },
  //       });
  //     }
  // }
  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      setCurrentLang("ar");
    }
    const currentLang = localStorage.getItem("lang") || "ar";
    document.documentElement.setAttribute("lang", currentLang);
    document.documentElement.setAttribute(
      "dir",
      currentLang === "ar" ? "rtl" : "ltr"
    );
    // handleMomentLocal(currentLang)
    currentLang === 'ar' ? dayjs.locale('ar') : dayjs.locale('en');  
  }, []);
  const handleWebSocket = async () => {
      if (isSocketConnected) {
        const { listenToSettings } = await import("../utils/websocket");
        listenToSettings();
      }
  }
  useEffect(() => {
    handleWebSocket()
  }, [isSocketConnected]);

  const addBreadCrumbs = async () => {
      const { addBreadcrumb } = await import("../store/Breadcrumbs/actions");
      const { generateBreadcrumbs } = await import("../utils/Shared");
      const breadcrumbs = generateBreadcrumbs();
      dispatch(addBreadcrumb(breadcrumbs));
  };

  useEffect(() => {
    addBreadCrumbs();
    return History.listen((history) => {
      addBreadCrumbs();
    });
  }, [History]);
  useEffect(() => {
    if(user?.role || userRoles){
      dispatch(GenerateNewRoutes(generateRoutes(user?.role || userRoles)));
    }
  }, [user?.role, userRoles]);

  useEffect(() => {
    if (History.location.pathname == "/login") {
      if (localStorage.getItem("is_loggedin") && History.action == "POP") {
        History.goForward();
      }
    }
  }, [window.location.href]);


  // Clear localStorage on tab/browser close and on refresh if session_expiration
  window.onbeforeunload = () => {
    localStorage.getItem('session_expiration') && localStorage.clear()
  }

  return (
    <HelmetProvider>
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div
          className={lang === "ar" ? "rtl" : "ltr"}
          dir={lang === "ar" ? "rtl" : "ltr"}
        >
          {loader.showLoader && <Loader />}
          <Routes routings={routes} >
            <SessionExpiration />
            {History.location.pathname == "/login" && <MaterialSnackbar />}
          </Routes>
        </div>
      </IntlProvider>
    </HelmetProvider>
  );
};

export default App;
